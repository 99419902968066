import FinnPulseInitializer from './index';
import { InitialTrackerState, PulseInitWrapper, SDKConfigInput } from './types';

class PulseInitUnshiftWrapper implements PulseInitWrapper {
    private readonly pulseFunction: (...args: any) => void;

    constructor(win: Window) {
        win.pulse.q = win.pulse.q || ([] as any);
        this.pulseFunction = function (...args: any) {
            // We use unshift here to set our init call at the start of the queue
            window.pulse.q.unshift(args);
        };
    }

    init(providerId: string, sdkConfigInput: SDKConfigInput, state: InitialTrackerState, trackerName?: string): void {
        this.pulseFunction('init', providerId, sdkConfigInput, state, trackerName);
    }
}

const hostname = location.hostname;
const isDev =
    hostname === '' || ['dev', 'local', 'toripg'].some((hostChunk) => location.hostname.indexOf(hostChunk) === 0);

function initTrackers() {
    initPulseQueue(window);
    const pulseFunctionWrapper = new PulseInitUnshiftWrapper(window);
    new FinnPulseInitializer(isDev, pulseFunctionWrapper);
}

function initPulseQueue(win: Window): void {
    win.pulse =
        win.pulse ||
        function (...args: any) {
            (win.pulse.q = win.pulse.q || []).push(args);
        };
}

initTrackers();
